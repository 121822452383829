import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

axios.defaults.baseURL = process.env.REACT_APP_API_URL_DEV;

const token = {
  set(token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  },
  unset() {
    axios.defaults.headers.common['Authorization'] = ``;
  },
};

const signIn = createAsyncThunk(
  'auth/signIn',
  async (credentials, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('/auth/login', credentials);
      console.log(data.data.token);
      token.set(data.data.token);
      toast.success(`Authorization is successful`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  },
);

const signUp = createAsyncThunk(
  'auth/signup',
  async ({ credentials }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('/auth/signup', credentials);
      token.set(data.data.token);
      toast.success(`Registration is successful`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  },
);

const refreshCurrentUser = createAsyncThunk(
  'auth/refreshCurrentUser',
  async (_, thunkApi) => {
    const state = thunkApi.getState();
    const persistedToken = state.auth.token;

    if (persistedToken === null) {
      token.unset();
      return thunkApi.rejectWithValue(state);
    } else {
      try {
        const { data } = await axios.get('/auth/refresh-token', {
          headers: { Authorization: `Bearer ${persistedToken}` },
        });
        token.set(persistedToken);
        return { persistedToken, user: data.user };
      } catch (error) {
        token.unset();
        return thunkApi.rejectWithValue(state);
      }
    }
  },
);

const googleIn = createAsyncThunk('auth/googleIn', ({ email, name }) => {
  return { email, name };
});

const logOut = createAsyncThunk(
  'auth/logOut',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('/auth/logout');
      toast.success(`Log out is successful `, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  },
);

const verifyEmail = createAsyncThunk(
  'auth/verifyEmail',
  async ({ token, navigate }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/auth/verify-email/${token}`);
      toast.success(`Verify is successful`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return navigate('/login');
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  },
);

const verifyRememberToken = createAsyncThunk(
  'auth/verifyRememberToken',
  async ({ validation_remember_token }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/auth/valid-remember-token`, {
        validation_remember_token,
      });
      return data.data;
    } catch (error) {
      toast.error(`${error.response.data.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  },
);

const isInfoClose = createAsyncThunk(
  'auth/isInfoClose',
  ({ isRegister, navigate }) => {
    navigate('/login');
    return isRegister;
  },
);

export default {
  signIn,
  googleIn,
  refreshCurrentUser,
  logOut,
  signUp,
  verifyEmail,
  isInfoClose,
  verifyRememberToken,
};
