import styled from 'styled-components';
import Button from '@mui/material/Button';
import { useState } from 'react';
import axios from 'axios';
import { validPassword } from '@root/helpers/validation';
import { toast } from 'react-toastify';
import { PasswordInput } from '@root/components';

const INIT_STATE = {
  oldPassword: '',
  newPassword: '',
  repeatPassword: '',
};

export const PasswordChange = () => {
  const [data, setData] = useState(INIT_STATE);
  const [error, setError] = useState({});

  const handleInputChange = event => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const validation = () => {
    let flag = true;
    const { newPassword, repeatPassword } = data;

    let localError = {};

    Object.keys(data).forEach(key => {
      if (!data[key] || data[key] === '') {
        localError = {
          ...localError,
          [key]: { init: true, text: "Обов'язкове поле" },
        };
        flag = false;
      }
    });

    if (newPassword !== '' && validPassword(newPassword)) {
      localError = {
        ...localError,
        newPassword: { init: true, text: 'Некоректний password' },
      };
      flag = false;
    }

    if (repeatPassword !== '' && repeatPassword !== newPassword) {
      localError = {
        ...localError,
        repeatPassword: { init: true, text: 'Ваш пароль не співпадає' },
      };
      flag = false;
    }

    setError(localError);

    return flag;
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (!validation()) {
      console.error('Паролі не співпадають');
      return;
    }

    try {
      const { oldPassword, newPassword, repeatPassword } = data;
      const response = await axios.post(
        'https://batafly.fly.dev/api/user/change-password',
        {
          oldPassword,
          newPassword,
          repeatPassword,
        },
      );

      if (response.status === 200) {
        toast.success(`Пароль успішно змінено`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setData({});
      }
    } catch (error) {
      toast.error(`Помилка при зміні пароля`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const { oldPassword, newPassword, repeatPassword } = data;
  return (
    <>
      <Wrapper>
        <Title>Заміна пароля</Title>
        <Form onSubmit={handleSubmit}>
          <PasswordInput
            error={error.oldPassword && error.oldPassword.init}
            helperText={error.oldPassword && error.oldPassword.text}
            label="Старий пароль"
            name="oldPassword"
            value={oldPassword}
            onChange={handleInputChange}
            size="small"
            margin="dense"
            autoComplete="off"
            sx={{ width: '100%' }}
          />

          <PasswordInput
            error={error.newPassword && error.newPassword.init}
            helperText={error.newPassword && error.newPassword.text}
            label="Новий пароль"
            name="newPassword"
            value={newPassword}
            onChange={handleInputChange}
            size="small"
            margin="dense"
            autoComplete="off"
          />

          <PasswordInput
            error={error.repeatPassword && error.repeatPassword.init}
            helperText={error.repeatPassword && error.repeatPassword.text}
            label="Підтвердіть пароль"
            name="repeatPassword"
            value={repeatPassword}
            onChange={handleInputChange}
            size="small"
            margin="dense"
            autoComplete="off"
          />

          <ButtonWrapper>
            <Button type="submit" variant="outlined">
              Зберегти
            </Button>
          </ButtonWrapper>
        </Form>
      </Wrapper>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  width: 100%;

  @media screen and (min-width: 830px) {
    width: 70%;
  }
`;

const Title = styled.strong`
  display: block;
  font-size: 18px;
  line-height: 1;
`;

const Wrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 16px;
`;
