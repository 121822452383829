import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import TextField from '@mui/material/TextField';

export const PasswordInput = ({
  error,
  label,
  value,
  onChange,
  size = "small",
  margin = "dense",
  autoComplete = "off",
  name,
  helperText
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      error={error}
      helperText={helperText}
      type={showPassword ? 'text' : 'password'}
      label={label}
      variant="outlined"
      name={name}
      value={value}
      onChange={onChange}
      size={size}
      margin={margin}
      autoComplete={autoComplete}
      sx={{ width: '100%' }}
      InputProps={{
        endAdornment: <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            onMouseUp={handleMouseUpPassword}
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>,
      }}
    />
  )
}



