import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { AudioPlayer } from '@root/components';
const { REACT_APP_HOST } = process.env

export const AudioPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();


  return (
    <>
      <VideoWrapper>
        <AudioPlayer src={REACT_APP_HOST + "/" + searchParams.get('url')} />
      </VideoWrapper>
    </>
  );
};

const VideoWrapper = styled.div`
  margin: 0 auto 25px auto;
  height: 300px;
  &,
  audio {
    width: 80vw;
    height: 300px !important;
  }
`;
