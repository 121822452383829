import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import operation from '@redux/auth/auth-operation';
import authSelectors from '@redux/auth/auth-selectors';
import style from 'styled-components';
import { styled, useTheme } from '@mui/material/styles';
import { theme } from '../../styles/theme';
import { Logo } from '@root/assets';
import Menu from '@mui/material/Menu';

import {
  Typography,
  IconButton,
  Toolbar,
  MenuItem,
  Avatar,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar from '@mui/material/AppBar';
import MenuMobile from '@root/components/MenuMobile/MenuMobile';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  backgroundColor: '#de5736',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: 240,
    width: `calc(100% - ${240}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Header = ({ open, setOpen }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { lessonId } = useParams();
  const name = useSelector(authSelectors.getUserName);
  const avatar = useSelector(authSelectors.getAvatar);
  const [openModalMenu, setOpenMenu] = useState(false);
  const [typeWindow, setTypeWindow] = useState('desktop');
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = e => {
    if (window.innerWidth <= 600) {
      setTypeWindow('mobile');
    } else {
      setTypeWindow('desktop');
    }
  };

  const handleDrawerOpen = () => {
    if (typeWindow === 'desktop') {
      setOpen(true);
    } else {
      setOpenMenu(true);
    }
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" open={open && !lessonId}>
      <Toolbar>
        {!lessonId && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
        )}

        {openModalMenu && <MenuMobile onClose={handleCloseMenu} />}

        <FlexBox>
          <img src={Logo} alt="Logo" width={20} height={37} />
        </FlexBox>

        <AvatarWrap sx={{ cursor: 'pointer' }} onClick={handleClick}>
          {name && (
            <AvatarLink>
              <Avatar
                src={avatar}
                alt={name}
                onError={e => {
                  e.target.style.display = 'none'; // Скрываем изображение, если ошибка
                }}
                sx={{
                  cursor: 'pointer',
                  marginRight: '8px',
                }}
              >
                {avatar ? null : name.charAt(0)}{' '}
                {/* Если изображение не загружено, отображаем первую букву имени */}
              </Avatar>
              <StyledTypography
                variant="subtitle1"
                sx={{
                  color: '#fff',
                  fontSize: '16px',
                  lineHeight: '20px',
                }}
              >
                {name}
              </StyledTypography>
            </AvatarLink>
          )}
        </AvatarWrap>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            component={Link}
            to="/profile/user-info"
            onClick={handleClose}
          >
            Профіль
          </MenuItem>
          <MenuItem
            onClick={() => {
              dispatch(operation.logOut());
              handleClose();
            }}
          >
            Вийти
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

const FlexBox = style.div`
  display: flex;
  margin-right: auto;
`;

const AvatarWrap = style.div`
position: relative;
`;

const AvatarLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

const StyledTypography = style(Typography)`
@media screen and (max-width: 760px) {
     display: none; 
  }
`;

