import React, { useRef, useEffect } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css'; // Импорт стилей Video.js

export const AudioPlayer = ({ src }) => {
  const audioRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [])

  useEffect(() => {
    if (!playerRef.current) {
      playerRef.current = videojs(audioRef.current, {
        controls: true,
        autoplay: false,
        responsive: true,
        fluid: true, // Плеер адаптируется к контейнеру
        preload: true,
        controlBar: {
          skipButtons: {
            forward: 5,
            backward: 5
          }
        },
        sources: [
          {
            src: src,
            type: 'audio/mp3',
          },
        ],
      });
    }
  }, [src]);



  return (
    <div data-vjs-player>
      <audio ref={audioRef} className="video-js vjs-default-skin" />
    </div>
  );
};


