import React, { useRef, useEffect } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css'; // Импорт стилей Video.js

export const VideoPlayer = ({ options, onReady }) => {
  const videoRef = useRef(null); // Реф на элемент видео
  const playerRef = useRef(null); // Реф на экземпляр плеера Video.js

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef])

  useEffect(() => {
    // Если плеер еще не создан, создаем его
    if (!playerRef.current) {
      const videoElement = videoRef.current;


      if (videoElement) {
        const player = videojs(videoElement, options, () => {
          onReady && onReady(player); // Вызываем колбек, если передан
        });

        playerRef.current = player;
      }
    }

  }, [options, onReady]);

  return (
    <div data-vjs-player>
      <video ref={videoRef} className="video-js vjs-big-play-centered" />
    </div>
  );
};

