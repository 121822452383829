import { useState, useEffect } from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import operation from '@redux/auth/auth-operation';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { validEmail } from '@root/helpers/validation';
import authSelectors from '@redux/auth/auth-selectors';
import { PasswordInput } from '@root/components';

export const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const rememberToken = useSelector(authSelectors.getValidationRememberToken);
  const navigation = useNavigate();

  const validation_remember_token = searchParams.get(
    'validation_remember_token',
  );

  useEffect(() => {
    if (!validation_remember_token) return;
    dispatch(operation.verifyRememberToken({ validation_remember_token }));
  }, [validation_remember_token]);

  useEffect(() => {
    if (!rememberToken) return;
    navigation('/refresh-password');
  }, [rememberToken]);

  const onInputChange = e => {
    switch (e.target.name) {
      case 'email':
        setEmail(e.target.value.trim());
        break;
      case 'password':
        setPassword(e.target.value.trim());
        break;

      default:
        break;
    }
  };
  const validation = () => {
    if (!email || email === '') {
      setError({ email: { init: true, text: "Обов'язкове поле" } });
      return true;
    }
    if (validEmail(email)) {
      setError({ email: { init: true, text: 'Некоректний email' } });
      return true;
    }
    if (!password || password === '') {
      setError({ password: { init: true, text: "Обов'язкове поле" } });
      return true;
    }
    if (password.length < 6) {
      setError({
        password: {
          init: true,
          text: 'Некоректний пароль',
        },
      });
      return true;
    }
    setError({});
    return false;
  };

  const handelSubmit = e => {
    e.preventDefault();
    if (!validation()) dispatch(operation.signIn({ email, password }));
  };

  return (
    <LoginWrap>
      <ModalLogin>
        <Title>Вас вітає Lesia Portuguese</Title>
        <Form onSubmit={handelSubmit}>
          <TextField
            error={error?.email?.init}
            helperText={error?.email?.text}
            id="outlined-basic"
            label="Email"
            variant="outlined"
            name="email"
            value={email}
            onInput={onInputChange}
            size="small"
            margin="dense"
            autoComplete="off"
            sx={{ width: '100%' }}
          />
          <PasswordInput
            error={error?.password?.init}
            helperText={error?.password?.text}
            label="Пароль"
            name="password"
            value={password}
            onChange={onInputChange}
            size="small"
            margin="dense"
            autoComplete="off"
          />
          <Button sx={{ marginTop: "10px" }} type="submit" variant="outlined">
            Вхід
          </Button>
        </Form>
        <Question>
          <Link to={'/forgot-password'}>Забули пароль? </Link>
        </Question>
        <Question>
          Ви не маєте акаунта?{' '}
          <Link to={'/sign_up'} style={{ color: '#1976d2' }}>
            Зареєструйтесь
          </Link>
        </Question>
      </ModalLogin>
    </LoginWrap>
  );
};

const LoginWrap = styled.div`
  display: flex;
  background-color: #de5736;
  height: 100vh;
  width: 100vw;
`;

const ModalLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background: white;
  width: 400px;
  margin: auto;
  border-radius: 20px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  width: 70%;

`;

const Title = styled.strong`
  display: block;
  font-size: 18px;
  line-height: 1;
`;

const Question = styled.p`
  display: block;
  font-size: 12px;
  line-height: 1;

  margin-top: 25px;
`;
