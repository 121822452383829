import { ModalBasic, Logic, FileLoader } from '@root/components';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import operations from '@root/redux/admin/admin-operation';
import { useDispatch } from 'react-redux';
import { serialize } from 'object-to-formdata';
import Checkbox from '@mui/material/Checkbox';

export const FormLesson = ({ handleClose, data }) => {
  const [title, setTitle] = useState();
  const [description, setDescription] = useState(null);
  const [videoFile, setVideoFile] = useState();
  const [theoryFile, setTheoryFile] = useState();
  const [homeworkFile, setHomeworkFile] = useState();
  const [updateFile, setUpdateFile] = useState({});
  const [isPublish, setIsPublish] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState({});

  const handelSubmit = e => {
    e.preventDefault();

    if (!validation()) {
      if (data) {
        let dataST = {
          name: title,
          description,
          is_active: isPublish,
          ...updateFile,
        };

        if (!videoFile?._id) {
          dataST = { ...dataST, video: videoFile };
        }

        if (!theoryFile?._id) {
          dataST = { ...dataST, theory: theoryFile };
        }

        if (!homeworkFile?._id) {
          dataST = { ...dataST, home_work: homeworkFile };
        }

        const sendDate = serialize(
          {
            ...dataST,
          },
          {
            indices: true,
            nullsAsUndefineds: true,
            booleansAsIntegers: false,
            noFilesWithArrayNotation: true,
          },
        );

        dispatch(
          operations.updateLesson({
            lessonId: data._id,
            formData: sendDate,
          }),
        ).then(el => handleClose());
      } else {
        const sendDate = serialize(
          {
            name: title,
            description,
            video: videoFile,
            theory: theoryFile,
            home_work: homeworkFile,
            is_active: isPublish,
          },
          {
            indices: true,
            nullsAsUndefineds: true,
            booleansAsIntegers: false,
            noFilesWithArrayNotation: true,
          },
        );
        dispatch(operations.createLesson(sendDate)).then(el => handleClose());
      }
    }
  };

  useEffect(() => {
    if (data) {
      const { name, description, video, theory, home_work, is_active } = data;
      setTitle(name);
      setDescription(description);
      setIsPublish(is_active)
      if (video.name) setVideoFile(video);
      if (theory.name) setTheoryFile(theory);
      if (home_work.name) setHomeworkFile(home_work);
    }
  }, [data]);

  const validation = () => {
    if (!title || title === '') {
      setError({ title: { init: true, text: 'Required' } });
      return true;
    }
    if (videoFile || theoryFile || homeworkFile) {
      if (!videoFile || videoFile === '') {
        setError({ videoFile: { init: true, text: 'Required' } });
        return true;
      }

      if (!theoryFile || theoryFile === '') {
        setError({ theoryFile: { init: true, text: 'Required' } });
        return true;
      }
      if (!homeworkFile || homeworkFile === '') {
        setError({ homeworkFile: { init: true, text: 'Required' } });
        return true;
      }
    }

    setError({});
    return false;
  };

  const onInputChange = e => {
    switch (e.target.name) {
      case 'title':
        setTitle(e.target.value);
        break;
      case 'description':
        setDescription(e.target.value);
        break;

      default:
        break;
    }
  };

  const handelDelete = e => {
    dispatch(operations.deleteLesson({ lessonId: data._id })).then(el =>
      handleClose(),
    );
  };

  const handelUpdate = name => (value, idx) => {
    setUpdateFile(prevState => ({
      ...prevState,
      [name + 'Id']: value,
    }));
  };


  return (
    <ModalBasic handleClose={handleClose}>
      <FlexContainer>
        <Form onSubmit={handelSubmit}>
          <Title>{data ? 'Edit' : 'Create'} the Lesson</Title>

          <TextField
            error={error?.title?.init}
            helperText={error?.title?.text}
            sx={{ width: '100%' }}
            id="outlined-basic"
            label="Title"
            variant="outlined"
            name="title"
            value={title}
            onInput={onInputChange}
            size="small"
            margin="dense"
          />

          <TextField
            sx={{ width: '100%' }}
            id="outlined-basic"
            label="Description"
            variant="outlined"
            name="description"
            value={description === null ? '' : description}
            onInput={onInputChange}
            size="small"
            margin="dense"
          />

          <FileLoader
            title={'Завантажити відео'}
            file={videoFile}
            setFile={setVideoFile}
            error={error?.videoFile}
            setUpdateFile={handelUpdate('video')}
          />

          <FileLoader
            title={'Завантажити теорію'}
            file={theoryFile}
            setFile={setTheoryFile}
            error={error?.theoryFile}
            setUpdateFile={handelUpdate('theory')}
          />

          <FileLoader
            title={'Завантажити домашку'}
            file={homeworkFile}
            setFile={setHomeworkFile}
            error={error?.homeworkFile}
            setUpdateFile={handelUpdate('home_work')}
          />
          <Checkline>
            <Checkbox
              checked={isPublish}
              onChange={e => setIsPublish(e.target.checked)}
              color="primary"
            />{' '}
            <Label>
              Активувати урок
            </Label>
          </Checkline>
          <Logic data={data} handelDelete={handelDelete} />
        </Form>
      </FlexContainer>
    </ModalBasic>
  );
};
const Label = styled.label`
  width: 100%;
  font-size: 16px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Checkline = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  line-height: 10px;
  margin-left: -20px;
  width: 100%;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 0;

  margin-top: 10px;

  button {
    margin-top: 10px;
  }
`;

const Title = styled.strong`
  display: block;
  font-size: 18px;
  line-height: 1;
`;


