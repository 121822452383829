import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { GameLinkForm } from '@root/components'
import { changeLoading } from '@root/redux/admin/admin-slice';
import { useDispatch } from 'react-redux';

export const HelpersTab = () => {
  const [typeOfModal, setTypeOfModal] = useState('');
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(changeLoading(true))
  }, [])

  return (
    <>
      <TableWrapper>
        <Card onClick={() => { setTypeOfModal("game_link") }}>
          <Title>Game Links</Title>
        </Card>
      </TableWrapper>
      {typeOfModal === "game_link" && <GameLinkForm handleClose={() => setTypeOfModal("")} />}
    </>
  );
};

const Card = styled.li`
  border: 1px solid orange;
  border-radius:16px;
  padding: 50px;
  cursor: pointer;

  &:hover{
    border: 2px solid orange;
    background-color: rgba(137, 131, 131, 0.18);
  }
`
const Title = styled.p`
  font-size: 20;
  font-weight: bold;
`

const TableWrapper = styled.ul`
  margin: 50px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 60px;
`;

