import styled from 'styled-components';
import { useState, useEffect, useRef } from 'react';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EllipsisText from 'react-ellipsis-text';
import { Cloud } from 'assets';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const MultiSetMenu = ({
  file,
  setFile,
  handelUpdate,
  handelDelete,
  accept,
  sx,
  copyPathUrl,
  title = "Додати аудіо файли"
}) => {
  const [changeIdxFile, setChangeIdxFile] = useState(null);
  const fileRef = useRef();

  useEffect(() => {
    fileRef.current.value = '';
    setChangeIdxFile(null);
  }, [file]);

  const handleFile = e => {
    const fileDownload = e.currentTarget.files;
    if (!fileDownload) {
      return;
    }
    if (fileDownload && typeof changeIdxFile !== 'number') {
      setFile([...file, ...fileDownload]);
    } else {
      setFile(
        file.map((el, i) => {
          if (i === changeIdxFile) {
            return fileDownload[0];
          } else {
            return el;
          }
        }),
      );
    }
  };

  const handelClick = e => {
    fileRef.current.multiple = true;
    fileRef.current.click();
  };

  const handleEdit = (fileChange, idx) => {
    setChangeIdxFile(idx);
    fileRef.current.multiple = false;
    fileRef.current.click();
    if (fileChange?._id) handelUpdate(fileChange?._id, idx);
  };

  const handleDelete = (fileChange, idx) => {
    setFile([...file].filter((el, i) => i !== idx));
    if (fileChange?._id) handelDelete(fileChange?._id, idx);
  };

  const copyUrl = path => navigator.clipboard.writeText(`${copyPathUrl}?url=${path}`);

  return (
    <Component style={{ ...sx }}>
      <File
        type="file"
        ref={fileRef}
        onChange={handleFile}
        accept={`${accept}`}
      />
      {file.length > 0 ? (
        <>
          <List>
            {file.map((el, i) => (
              <Item key={i} id={i}>
                <Typography
                  sx={{ color: '#364A63', fontSize: 12 }}
                  align={'center'}
                  component={'p'}
                >
                  <EllipsisText text={el.name} length={'40'} />
                </Typography>

                <WrapperBtn>
                  {el.path && <IconButton
                    aria-label="copy"
                    size="small"
                    onClick={() => copyUrl(el.path)}
                  >
                    <ContentCopyIcon />
                  </IconButton>}
                  <IconButton
                    aria-label="upload"
                    size="small"
                    onClick={() => handleEdit(el, i)}
                  >
                    <FileUploadRoundedIcon />
                  </IconButton>

                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => handleDelete(el, i)}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </WrapperBtn>
              </Item>
            ))}
          </List>
          <ButtonAdd type="button" onClick={handelClick}>
            <AddIcon />
          </ButtonAdd>
        </>
      ) : (
        <>
          <CloudIcon src={Cloud} alt="Imag Cloud" />
          <Typography
            sx={{ color: '#364A63', fontSize: 12 }}
            align={'center'}
            component={'p'}
          >
            {title}
          </Typography>
          <ClickMe type="button" onClick={handelClick}>
            {' '}
            <Typography
              sx={{ mt: '4px', color: '#526484', fontSize: 12 }}
              align={'center'}
              component={'p'}
            >
              Нажми на мене щоб додати
            </Typography>
          </ClickMe>
        </>
      )}
    </Component>
  );
};

const Component = styled.div`
  padding: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #c5c8d2;
`;

const List = styled.ul`
  width: 100%;
`;

const Item = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 44px;
  border: 1px solid #000;
  padding: 0 10px;
  button {
    margin-top: 0 !important;
  }
`;

const ClickMe = styled.button`
  cursor: pointer;
`;
const ButtonAdd = styled.button`
  width: 100%;
  cursor: pointer;
  border: 1px solid #000;
`;
const File = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
`;

const WrapperBtn = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`;

const CloudIcon = styled.img`
  margin-left: auto;
  margin-right: auto;
`;
