import { useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { CardItem } from '@root/components';
import { useDispatch, useSelector } from 'react-redux';
import homeSelectors from '@redux/home/home-selectors';
import { Link } from 'react-router-dom';
import operation from '@redux/home/home-operation';

export const Course = () => {
  const course = useSelector(homeSelectors.getCourse);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(operation.getCourse());
  }, []);

  return (
    <List>
      {course.map((el, i) => (
        <ListItem key={i} sx={{ display: 'block' }}>
          <Link to={`/${el._id}`}>
            <CardItem data={el} trash={true} />
          </Link>
        </ListItem>
      ))}
    </List>
  );
};
