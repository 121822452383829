import { useState } from 'react';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { validEmail } from '@root/helpers/validation';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState({});
  const [serverErrors, setServerErrors] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  const onInputChange = e => {
    switch (e.target.name) {
      case 'email':
        setEmail(e.target.value.trim());
        break;
      default:
        break;
    }
  };

  const validation = () => {
    if (!email || email === '') {
      setError({ email: { init: true, text: "Обов'язкове поле" } });
      return true;
    }
    if (validEmail(email)) {
      setError({ email: { init: true, text: 'Некоректний email' } });
      return true;
    }
    setError({});
    return false;
  };

  const handleServerErrors = error => {
    setServerErrors(prevErrors => [...prevErrors, error.message]);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!validation()) {
      try {
        const rememberPasswordResponse = await fetch(
          'https://batafly.fly.dev/api/auth/remember-password',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
          },
        );

        if (rememberPasswordResponse.ok) {
          const rememberPasswordData = await rememberPasswordResponse.json();

          setSubmitted(true);
        } else {
          const rememberPasswordText = await rememberPasswordResponse.text();
          console.error('Remember Password Error:', rememberPasswordText);
          handleServerErrors({ message: rememberPasswordText });
        }
      } catch (error) {
        console.error('An error occurred during the request:', error.message);
        handleServerErrors(error);
      }
    }
  };
  return (
    <>
      <LoginWrap>
        {submitted ? (
          <Container>
            <Message>
              Вам на пошту було надіслано повідомлення з інструкцією щодо
              подальших дій.
            </Message>
            <ButtonWrapper>
              <Link to={'/login'}>
                <Button type="submit" variant="outlined">
                  Логін
                </Button>
              </Link>
            </ButtonWrapper>
          </Container>
        ) : (
          <ModalLogin>
            <Title>Забули пароль</Title>
            <Description>
              Якщо ви забули ваш пароль, введіть будь ласка ваш імейл.
            </Description>
            <Form onSubmit={handleSubmit}>
              <TextField
                error={error.email && error.email.init}
                helperText={error.email && error.email.text}
                id="outlined-basic"
                label="Email"
                variant="outlined"
                name="email"
                value={email}
                onChange={onInputChange}
                size="small"
                margin="dense"
                autoComplete="off"
                inputProps={{ maxLength: 100 }}
                sx={{ width: '100%' }}
              />
              <ButtonWrapper>
                <Button type="submit" variant="outlined">
                  Надіслати
                </Button>
              </ButtonWrapper>
            </Form>

            <Backto>
              Повернутись до{' '}
              <Link to={'/login'} style={{ color: '#1976d2' }}>
                Логіну
              </Link>
            </Backto>
          </ModalLogin>
        )}
      </LoginWrap>
    </>
  );
};

export default ForgotPassword;

const Title = styled.strong`
  display: block;
  font-size: 18px;
  line-height: 1;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  width: 70%;

  button {
    margin-top: 10px;
  }
`;

const Description = styled.p`
  display: block;
  font-size: 12px;
  line-height: 1.7;
  text-align: center;
  margin-top: 25px;
  font-size: 16px;
`;

const LoginWrap = styled.div`
  display: flex;
  background-color: #de5736;
  height: 100vh;
  width: 100vw;
`;

const ModalLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background: white;
  width: 800px;
  margin: auto;
  border-radius: 20px;

  @media (max-width: 375px) {
    width: 100%;
  }
`;

const Message = styled.p`
  text-align: left;
  font-size: 14px;
  margin-bottom: 14px;
  line-height: 1.5;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background: white;
  width: 460px;
  margin: auto;
  border-radius: 20px;
`;

const Backto = styled.p`
  display: block;
  font-size: 12px;
  line-height: 1;

  margin-top: 25px;
`;
