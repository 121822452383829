export const usersConf = [
  { label: 'Name', value: 'name', type: 'name', style: {} },
  { label: 'Email', value: 'email', type: 'email', style: {} },
  {
    label: 'Subscription',
    value: 'subscription',
    type: 'lvl',
    style: {},
  }, // change
  {
    label: 'Verify',
    value: 'isVerify',
    type: 'bool',
    style: {},
  }, // change
  {
    label: 'Course',
    value: 'course',
    type: 'action',
    style: {},
  }, // change
  {
    label: '',
    value: 'action',
    type: 'action',
    style: {},
    key: 'users',
  }, // change
];

export const courseConf = [
  { label: 'Name', value: 'name', type: 'name', style: {} },
  {
    label: 'lvl',
    value: 'lvl',
    type: 'lvl',
    style: {},
  },
  {
    label: 'Description',
    value: 'description',
    type: 'description',
    style: {},
  },
  {
    label: '',
    value: 'action',
    type: 'action',
    style: {},
    key: 'course',
  },
];

export const moduleConf = [
  { label: 'Name', value: 'name', type: 'name', style: {} },
  {
    label: '',
    value: 'action',
    type: 'action',
    style: {},
    key: 'course',
  },
];

export const lessonConf = [
  { label: 'Name', value: 'name', type: 'name', style: {} },
  {
    label: 'Description',
    value: 'description',
    type: 'description',
    style: {},
  },
  {
    label: '',
    value: 'action',
    type: 'action',
    style: {},
    key: 'course',
  },
];

export const audioConf = [
  { label: 'Name ', value: 'name', type: 'name', style: {} },
  {
    label: 'Path',
    value: 'localPath',
    type: 'description',
    style: {},
  },
  {
    label: '',
    value: 'action',
    type: 'action',
    style: {},
    key: 'course',
  },
];
