import { Card, Typography, CardContent } from '@mui/material';

import styled from 'styled-components';

export const CardItem = ({ data, index, trash }) => {
  const pad = value => {
    return String(value).padStart(2, '0');
  };

  return (
    <Card sx={{ maxWidth: '100%', display: 'flex', cursor: 'pointer' }}>
      <BoxLevel>
        {data?.course_logo && <Img src={data?.course_logo.path} alt="" />}
        <Marck>{trash ? index : pad(index + 1)}</Marck>
      </BoxLevel>
      <CardContent sx={{ width: '100%' }}>
        <Typography gutterBottom variant="h5" component="div">
          {data.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {data.description}
        </Typography>
      </CardContent>
    </Card>
  );
};

const BoxLevel = styled.div`
  background-color: gray;
  
  width: 100px;
  height: 100px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
`

const Marck = styled.p`
  position: absolute;
  
  font-size: 40px;
  line-height: 1;
  color: white;
`;
