import { useEffect, useState } from 'react';
import { ListItem, List, AppBar } from '@mui/material';
import { PdfViewer, ColorTabs, VideoPlayer } from '@root/components';
import '@react-pdf-viewer/core/lib/styles/index.css';
import styled from 'styled-components';
import { useParams } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

export const tabsConf = [
  { label: 'Відео', value: 'video', },
  { label: 'Теорія', value: 'theory', },
  { label: 'Домашне завдання', value: 'home_work', },
];

export const LessonsDetails = ({ data }) => {
  const { courseId, lessonId, tab } = useParams();
  const [video, setVideo] = useState('');
  const [theory, setTheory] = useState('');
  const [home, setHome] = useState('');
  const navigation = useNavigate();

  useEffect(() => {
    if (data?.video) {
      setVideo(data?.video?.path);
      setTheory(data?.theory?.path);
      setHome(data?.home_work?.path);
    }
  }, [data]);

  const handlePlayerReady = (player) => {
  };

  const getSection = () => {
    switch (tab) {
      case "video":
        const videoJsOptions = {
          controls: true,
          responsive: true,
          fluid: true, // Плеер адаптируется к контейнеру
          preload: true,
          playbackRates: [0.5, 1, 1.5, 2],
          controlBar: {
            skipButtons: {
              forward: 5,
              backward: 5
            }
          },
          sources: [
            {
              src: video,
              type: 'video/mp4',
            },
            {
              src: video,
              type: 'video/quicktime',
            },
          ],
        };
        return (
          <VideoWrapper>
            {video ? (
              <ListItem style={{ justifyContent: 'center', width: "100%", height: "100%" }}>
                {video && (<VideoPlayer options={videoJsOptions} onReady={handlePlayerReady} />)}
              </ListItem >
            ) : (
              <SpinnerWrapper>
                <CircularProgress color="inherit" />
              </SpinnerWrapper>
            )}
          </VideoWrapper>
          // <iframe width="560" height="315" src="https://www.youtube.com/embed/rNemZ-_CONE?si=Bh68HDxiwvMhM0Yo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        )

      case "theory":
        return (<ListItem style={{ justifyContent: 'center' }}>
          {theory ? (
            <PdfViewer fileUrl={theory} />
          ) : (
            <SpinnerWrapper>
              <CircularProgress color="inherit" />
            </SpinnerWrapper>
          )}
        </ListItem>)

      default:
        return (<ListItem style={{ justifyContent: 'center', px: 5 }}>
          {home ? (
            <PdfViewer fileUrl={home} />
          ) : (
            <SpinnerWrapper>
              <CircularProgress color="inherit" />
            </SpinnerWrapper>
          )}
        </ListItem>)
    }
  }

  return (
    <Wrapper>
      <AppBar
        sx={{
          position: 'relative',
          backgroundColor: '#9e9e9ed1',
          p: '15px 5px',
          mb: "5px"
        }}
      >
        <GoBackBtn type="button" onClick={() => navigation(`/${courseId}`)}>
          <ArrowBackIcon sx={{ color: '#ffffff' }} />
          <Text>Назад</Text>
        </GoBackBtn>
      </AppBar>
      <ColorTabs conf={tabsConf} path={`/${courseId}/${lessonId}`} />
      <List>
        {getSection()}
      </List>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const GoBackBtn = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Text = styled.p`
  font-size: 20px;
  margin-left: 5px;
  color: #fff;
`;

const VideoWrapper = styled.div`
  margin: 0 auto 25px auto;
  width: 80vw;
  max-height: 82vh;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 90vw;
  padding: 50px 0;
`;
