import { useEffect } from 'react';
import { useParams } from 'react-router';
import { LessonsDetails } from '@root/modules';
import { useSelector, useDispatch } from 'react-redux';
import operation from '@redux/home/home-operation';
import homeSelectors from '@redux/home/home-selectors';
import { clearLessons } from '@root/redux/home/home-slice';

export const LessonPage = () => {
  const { lessonId } = useParams();
  const dispatch = useDispatch();
  const lesson = useSelector(homeSelectors.getLesson);

  useEffect(() => {
    dispatch(operation.getLessonsByID({ lessonId }));
    return (() => {
      dispatch(clearLessons());
    })
  }, []);

  return (
    <>
      <LessonsDetails data={lesson} />
    </>
  );
};
