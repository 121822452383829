import {
  UsersTabs,
  CourseTabs,
  ModuleTabs,
  LessonsTabs,
  AudioTabs,
  HelpersTab,
} from '@root/modules';

export const tabsConf = [
  { label: 'Users', value: 'users', element: <UsersTabs /> },
  { label: 'Course', value: 'course', element: <CourseTabs /> },
  { label: 'Module', value: 'module', element: <ModuleTabs /> },
  { label: 'Lessons', value: 'lessons', element: <LessonsTabs /> },
  { label: 'Audio', value: 'audio', element: <AudioTabs /> },
  { label: 'Helpers', value: 'helpers', element: <HelpersTab /> },
];
