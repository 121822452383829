import { useState, useEffect } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

export const ColorTabs = ({ conf, path = "" }) => {
  const [value, setValue] = useState('users');
  const navigate = useNavigate();
  const { tab } = useParams();

  useEffect(() => {
    navigate(`${path}/${tab}`);
    setValue(tab);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`${path}/${newValue}`);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="inherit"
        TabIndicatorProps={{
          style: {
            backgroundColor: '#de5736',
          },
        }}
        sx={{
          '& .MuiTab-root': {
            color: '#de5736',
          },
          '& .Mui-selected': {
            color: '#de5736',
          },
        }}
        aria-label="secondary tabs example"
      >
        {conf.map(({ value, label }, i) => (
          <Tab value={value} label={label} key={i} />
        ))}
      </Tabs>
    </Box>
  );
};
